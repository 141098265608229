<script>
import Form from "./components/NewMessageThreadForm.vue";

export default {
  name: "NewThreadView",
  components: {
    Form,
    DetailViewDesktop: () => import("./components/DetailViewDesktop.vue"),
    DetailViewMobile: () => import("./components/DetailViewMobile.vue"),
  },
  computed: {
    reactiveComponent() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return "DetailViewDesktop";
      }
      return "DetailViewMobile";
    },
  },
};
</script>

<template>
  <component :is="reactiveComponent" back="/texting" title="New conversation">
    <Form />
  </component>
</template>
