<template>
  <v-form ref="form" v-model="valid">
    <v-autocomplete
      v-if="computedOpenBuys.length > 0"
      v-model="buyId"
      clearable
      label="Open buy"
      item-text="label"
      return-object
      :items="computedOpenBuys"
      filled
      @change="autopopulate"
    />
    <v-autocomplete
      v-model="storeMessage"
      clearable
      label="Message *"
      return-object
      item-text="title"
      :items="prewrittenTexts"
      filled
      :rules="messageRules"
    />
    <v-text-field v-model="name" clearable label="Name" filled />
    <v-text-field
      v-model="transId"
      clearable
      label="Transaction ID"
      inputmode="numeric"
      pattern="[0-9]*"
      filled
    />
    <v-text-field
      ref="cellphone"
      v-model="cellphone"
      clearable
      label="Cellphone # *"
      mask="phone"
      :error-messages="cellphoneErrors"
      filled
      inputmode="numeric"
      pattern="[0-9]*"
      :rules="cellphoneRules"
      @input="validate"
    />
    <v-btn :disabled="!valid" :loading="loading" color="accent" @click="create"
      >Send</v-btn
    >
    <v-btn @click="$refs.form.reset()">Reset</v-btn>
  </v-form>
</template>

<script>
import { mapMutations } from "vuex";
import { removeAreaCode, normalize } from "@/utils/phonenumber";
import { TEXTING_VIEW, STORE_MESSAGES_QUERY, MESSAGE_CREATE } from "../graphql";
import { debounce } from "lodash";

export default {
  name: "NewMessageThreadForm",
  data: () => ({
    valid: false,
    buys: [
      // {
      //   id: 1,
      //   transId: 123456,
      //   customer: {
      //     full_name: "BJ Bryant",
      //     phoneNumber: "+18122160082"
      //   }
      // }, ...
    ],
    buyId: undefined,
    storeMessage: null,
    name: "",
    transId: "",
    cellphone: null,
    cellphoneRules: [(v) => !!v || "A cellphone is required"],
    messageRules: [(v) => !!v || "A message is required"],
    nameRules: [(v) => (!!v && /^[\w\s]+$/.test(v)) || "Name is required"],
    transIdRules: [(v) => !!v || "A transaction id is required"],
    loading: false,
    cellphoneErrors: null,
  }),
  apollo: {
    buys: () => ({
      query: TEXTING_VIEW,
      variables() {
        return {
          storeId: this.$route.params.storeId,
        };
      },
      skip() {
        return !this.$route.params.storeId;
      },
    }),
    prewrittenTexts: {
      query: STORE_MESSAGES_QUERY,
    },
  },
  computed: {
    // ...mapGetters("stores", ["prewrittenTexts"]),
    computedOpenBuys() {
      return this.buys.map((b) => ({
        ...b,
        label: `${b.transId} - ${b.customer.fullName}`,
        customer: {
          ...b.customer,
          phoneNumber: removeAreaCode(b.customer.phoneNumber),
        },
      }));
    },
    mutationInput() {
      return {
        storeMessageId: this.storeMessage.id,
        buyTransId: this.transId || null,
        name: this.name,
        cellphone: normalize(this.cellphone),
      };
    },
    variables() {
      return {
        input: {
          ...this.mutationInput,
        },
      };
    },
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    autopopulate(buy) {
      if (!buy) return;
      this.name = buy.customer.fullName;
      this.cellphone = buy.customer.phoneNumber;
      this.transId = buy.transId;
    },
    create() {
      this.loading = true;
      const variables = { ...this.variables };
      this.$apollo
        .mutate({
          mutation: MESSAGE_CREATE,
          variables,
        })
        .then(() => {
          this.showSnackbar({ text: "Message sent successfully." });
          this.$refs.form.reset();
        })
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while sending message. ${error}`,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
    validate: debounce(function (v) {
      const msg = "A valid cellphone # is required";
      if (!v || v.length === 0) {
        this.cellphoneErrors = null;
      } else if (/^\d{10}$/.test(v)) {
        this.cellphoneErrors = null;
      } else {
        this.cellphoneErrors = [msg];
      }
    }, 300),
  },
};
</script>
